import { CertificateProfile } from '../certificateProfiles/types';

export enum RequestType {
  Certificate = 'Certificate',
  CodeSigning = 'CodeSigning',
  CodeSigningCertificate = 'CodeSigningCertificate',
  DeviceBatch = 'DeviceBatch',
}

export type KeyValue = { [key: string]: string };

export interface Request {
  uuid?: string;
  notes?: string;
  actions?: string[];
  createdBy?: string;
  isCreatedByUserDeleted?: boolean;
  requestorUuid: string;
  updatedBy?: string;
  issuedAt?: string;
  updatedAt?: string;
  status?: string;
  type: RequestType;
}

export interface CertificateRequest extends Request {
  issuingCAUuid: string;
  quantity?: number;
  batch?: boolean;
  csr: string;
  csrFilename: string;
  certificateBatchId?: string;
  certificateSubjectVars?: KeyValue;
  certificateUuid?: string;
  certificateRequestId?: string;
  certificateProfile: CertificateProfile;
  certificateProfileId?: string;
}

export interface CodeSigningRequest extends Request {
  codeSigningProfileUuid: string;
  codeSigningProfileName?: string;
  certificateUuid?: string;
  signature?: string;
  outputFormat?: string;
  approverNotes?: string;
  digest: string;
}

export interface CodeSigningCertificateRequest extends Request {
  issuingCAUuid: string;
  certificateProfileUuid: string;
  certificateUuid?: string;
  certificateSubjectVars?: KeyValue;
  approverNotes?: string;
  certificateCN?: string;
  wrappedKey?: File;
}

export interface RequestsState {
  readonly isGettingCertificateRequestList: boolean;
  readonly isLoadedCertificateRequestList: boolean;
  readonly isGettingRequestCounter: boolean;
  readonly isLoadedRequestCounter: boolean;
  readonly isGettingCodeSigningRequestList: boolean;
  readonly isLoadedCodeSigningRequestList: boolean;
  readonly isGettingCodeSigningKeyRequestList: boolean;
  readonly isLoadedCodeSigningKeyRequestList: boolean;
  readonly isGettingAwaitingRequestList: boolean;
  readonly isLoadedAwaitingRequestList: boolean;
  readonly isEditingRequestStatus: boolean;
  readonly isEditingRequest: boolean;
  readonly isCreatingRequest: boolean;
  readonly certificateBatchInDownload: string; // 0 means no download
  readonly requestList: Request[];
  readonly awaitingRequestList: Request[];
  readonly certificateRequestList: CertificateRequest[];
  readonly codeSigningKeyRequestList: CodeSigningCertificateRequest[];
  readonly codeSigningRequestList: CodeSigningRequest[];
  readonly requestCounter: { [key: string]: number };
  readonly requestsListErrors: unknown;
}

export enum RequestActionTypes {
  GET_CERTIFICATE_REQUEST_LIST_REQUEST = '@@Request/GET_CERTIFICATE_REQUEST_LIST_REQUEST',
  GET_CERTIFICATE_REQUEST_LIST_SUCCESS = '@@Request/GET_CERTIFICATE_REQUEST_LIST_SUCCESS',
  GET_CERTIFICATE_REQUEST_LIST_ERROR = '@@Request/GET_CERTIFICATE_REQUEST_LIST_ERROR',

  GET_CODE_SIGNING_REQUEST_LIST_REQUEST = '@@Request/GET_CODE_SIGNING_REQUEST_LIST_REQUEST',
  GET_CODE_SIGNING_REQUEST_LIST_SUCCESS = '@@Request/GET_CODE_SIGNING_REQUEST_LIST_SUCCESS',
  GET_CODE_SIGNING_REQUEST_LIST_ERROR = '@@Request/GET_CODE_SIGNING_REQUEST_LIST_ERROR',

  GET_CODE_SIGNING_KEY_REQUEST_LIST_REQUEST = '@@Request/GET_CODE_SIGNING_KEY_REQUEST_LIST_REQUEST',
  GET_CODE_SIGNING_KEY_REQUEST_LIST_SUCCESS = '@@Request/GET_CODE_SIGNING_KEY_REQUEST_LIST_SUCCESS',
  GET_CODE_SIGNING_KEY_REQUEST_LIST_ERROR = '@@Request/GET_CODE_SIGNING_KEY_REQUEST_LIST_ERROR',

  GET_AWAITING_REQUEST_LIST_REQUEST = '@@Request/GET_AWAITING_REQUEST_LIST_REQUEST',
  GET_AWAITING_REQUEST_LIST_SUCCESS = '@@Request/GET_AWAITING_REQUEST_LIST_SUCCESS',
  GET_AWAITING_REQUEST_LIST_ERROR = '@@Request/GET_AWAITING_REQUEST_LIST_ERROR',

  GET_REQUEST_COUNTER_REQUEST = '@@Request/GET_REQUEST_COUNTER_REQUEST',
  GET_REQUEST_COUNTER_SUCCESS = '@@Request/GET_REQUEST_COUNTER_SUCCESS',
  GET_REQUEST_COUNTER_ERROR = '@@Request/GET_REQUEST_COUNTER_ERROR',

  POST_REQUEST_REQUEST = '@@Request/POST_REQUEST_REQUEST',
  POST_REQUEST_SUCCESS = '@@Request/POST_REQUEST_SUCCESS',
  POST_REQUEST_ERROR = '@@Request/POST_REQUEST_ERROR',

  PUT_REQUEST_REQUEST = '@@Request/PUT_REQUEST_REQUEST',
  PUT_REQUEST_SUCCESS = '@@Request/PUT_REQUEST_SUCCESS',
  PUT_REQUEST_ERROR = '@@Request/PUT_REQUEST_ERROR',

  PUT_REQUEST_STATUS_REQUEST = '@@Request/PUT_REQUEST_STATUS_REQUEST',
  PUT_REQUEST_STATUS_SUCCESS = '@@Request/PUT_REQUEST_STATUS_SUCCESS',
  PUT_REQUEST_STATUS_ERROR = '@@Request/PUT_REQUEST_STATUS_ERROR',

  DOWLOAD_BATCH_CERTIFICATES_REQUEST = '@@Request/DOWLOAD_BATCH_CERTIFICATES_REQUEST',
  DOWLOAD_BATCH_CERTIFICATES_SUCCESS = '@@Request/DOWLOAD_BATCH_CERTIFICATES_SUCCESS',
  DOWLOAD_BATCH_CERTIFICATES_ERROR = '@@Request/DOWLOAD_BATCH_CERTIFICATES_ERROR',
}
