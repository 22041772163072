import React, { FC } from 'react';
import { Button, UncontrolledPopover, UncontrolledTooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

interface CustomCSVExportButtonProps {
  data: any[];
  columns: {
    dataField: string;
    text: string;
    hidden?: boolean;
    sort?: boolean;
    csvExport?: boolean;
    csvFormatter?: Function;
    formatter?: Function;
  }[];
  exportCSV: {
    fileName?: string;
    onlyExportSelection?: boolean;
    exportAll?: boolean;
    customExport?: boolean;
  };
  selectedRows: number[];
  keyField: string;
  disabled?: boolean;
}

const tableJsonToCSV = (
  data: CustomCSVExportButtonProps['data'],
  columns: CustomCSVExportButtonProps['columns'],
  keyField: string,
  selectedRows: number[]
) => {
  const headers: {
    text: string;
    formatter: Function | undefined;
    dataField: string;
    columnIndex: number;
  }[] = [];
  columns.forEach((column, index) => {
    if (!(column.csvExport === false || column.hidden === true))
      headers.push({
        text: column.text,
        dataField: column.dataField,
        formatter: column.csvFormatter || column.formatter || undefined,
        columnIndex: index,
      });
  });
  let dataToExport = data;
  if (selectedRows.length !== 0) {
    dataToExport = data.filter((row) => selectedRows.includes(row[keyField]));
  }
  const headersText = headers.map((header) => `"${header.text}"`).join(',');
  const dataToExportArray = dataToExport.map((row) => {
    return headers
      .map((header) => {
        return header.formatter
          ? `"${header.formatter(row[header.dataField]).replaceAll('"', '""')}"`
          : `"${row[header.dataField].replaceAll('"', '""')}"`;
      })
      .join(',');
  });
  return [headersText, ...dataToExportArray].join(`\r\n`);
};

export const CustomCSVExportButton: FC<CustomCSVExportButtonProps> = ({
  data,
  columns,
  exportCSV,
  selectedRows,
  keyField,
  disabled = false,
}) => {
  const exportSize =
    selectedRows.length > 0 ? selectedRows.length : data.length;
  const handleDownloadCSV = (csv: string, fileName: string) => {
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
    const blobUrl = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = blobUrl;
    link.download = fileName || 'export.csv';
    document.body.appendChild(link);
    link.click();
    link.remove();
  };
  const handleClick = () => {
    const tableCSV = tableJsonToCSV(data, columns, keyField, selectedRows);
    handleDownloadCSV(tableCSV, exportCSV?.fileName || 'export.csv');
  };
  return (
    <Button
      outline
      disabled={data.length === 0 || disabled}
      className={'mr-2'}
      size={'sm'}
      id={'custom-export-csv'}
      onClick={handleClick}
    >
      <UncontrolledPopover
        innerClassName="text-muted"
        placement="top"
        trigger={'hover'}
        target={'custom-export-csv'}
      >
        <span className={'p-2'}>
          Export {exportSize.toLocaleString()} record(s)
        </span>
      </UncontrolledPopover>
      <FontAwesomeIcon icon={faDownload} /> Export to CSV
    </Button>
  );
};
