import React, { useState, useEffect, useRef, FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faUnlock, faLock } from '@fortawesome/free-solid-svg-icons';
import {
  Row,
  Col,
  Label,
  FormGroup,
  Input,
  Button,
  Collapse,
} from 'reactstrap';
import { includes, isEmpty, set, size } from 'lodash';
import './DeviceBatchRequestForm.scss';

import { useDispatch } from 'react-redux';
import { defaultValuesDeviceBatchRequest } from './constants';
import { DeviceBatchRequest } from './types';
import { ProductProfile } from '../ProductProfiles/types';
import { ServerSideSelect } from '../../components/ServerSideSelect/ServerSideSelect';
import { deserializeProductProfile } from '../ProductProfiles/helpers';
import { api, getVariablesFromSubject } from '../../libs/helpers';
import { sendNotification } from '../../store/notifications/actions';
import { combineAndDestructSubjectVars } from '../ViewsComponents/helpers';
import { KeyNInput, Spinner } from '../../components';
import {
  deserializeDeviceBatchRequest,
  serializeDeviceBatchParameters,
  serializeDeviceBatchRequest,
} from './helpers';

type PartialDeviceBatchRequest = Partial<DeviceBatchRequest>;

interface Props {
  onCancel?: Function;
  onSubmit?: Function;
  onDownloadDeviceBatch?: Function;
  onChangeMode?: Function;
  defaultValues?: PartialDeviceBatchRequest;
  readOnly: boolean;
}

const DeviceBatchRequestForm: FC<Props> = ({
  onCancel = (): null => null,
  onSubmit = (): null => null,
  onDownloadDeviceBatch = (): null => null,
  onChangeMode = (): null => null,
  defaultValues = defaultValuesDeviceBatchRequest,
  readOnly = false,
}) => {
  const dispatch = useDispatch();
  const [
    currentProductProfile,
    setCurrentProductProfile,
  ] = useState<ProductProfile>();

  const [highlightMandatoryFields, setHighlightMandatoryFields] = useState(
    false
  );

  const editMode = !readOnly && !isEmpty(defaultValues.uuid);

  const [requestForm, setRequestForm] = useState<PartialDeviceBatchRequest>(
    defaultValues
  );
  const {
    quantity = 0,
    actions = [],
    batchParameters,
    batchId,
    productProfileUuid,
    status,
    createdAt,
    notes = '',
    actionNotes,
    createdBy,
  } = requestForm;

  const canEdit = actions.includes('update');

  const setRequestFormProperty = ({
    property,
    value,
  }: {
    property: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any;
  }): void => {
    setRequestForm((current: PartialDeviceBatchRequest) => ({
      ...current,
      [property]: value,
    }));
  };

  const fetchProductProfile = async () => {
    if (!canEdit && defaultValues.productProfile) {
      setCurrentProductProfile(defaultValues.productProfile);
    } else {
      try {
        const { data: profile } = await api().get(
          `product-profile/${defaultValues.productProfileUuid}`
        );
        setCurrentProductProfile(deserializeProductProfile(profile));
      } catch (err) {
        const text =
          JSON.stringify(err?.response?.data?.detail) ||
          'Oops! Something went wrong fetching the Product Profile!';

        sendNotification({
          text,
          success: false,
        })(dispatch);
      }
    }
  };

  const getDeviceRequestDetails = async () => {
    if (readOnly && defaultValues.uuid) {
      try {
        const { data: batchRequest } = await api().get(
          `device/request/${defaultValues.uuid}`
        );
        setRequestForm(deserializeDeviceBatchRequest(batchRequest));
      } catch (err) {
        const text =
          JSON.stringify(err?.response?.data?.detail) ||
          'Oops! Something went wrong fetching the Device Batch Request!';

        sendNotification({
          text,
          success: false,
        })(dispatch);
      }
    }
  };
  useEffect(() => {
    if (!isEmpty(defaultValues.uuid)) {
      // No need to fetch the product profile after approval or rejection.
      // Commented it out and leave it here in case we need it again.
      // fetchProductProfile();
      getDeviceRequestDetails();
    }
  }, [readOnly, defaultValues]);
  useEffect(() => {
    if (currentProductProfile && !readOnly) {
      const { metadata, secrets = [] } = currentProductProfile;
      const metadataAndSecretsToKeyValues = [
        ...(metadata || []),
        ...(secrets || []),
      ].map((metaOrSecret) => {
        return {
          key: metaOrSecret.name,
          value: metaOrSecret.value,
        };
      });
      const combinedMetadataAndSecrets = combineAndDestructSubjectVars(
        metadataAndSecretsToKeyValues,
        []
      );
      const value = currentProductProfile
        ? getVariablesFromSubject(
            combinedMetadataAndSecrets,
            readOnly
              ? batchParameters
              : serializeDeviceBatchParameters(batchParameters || {})
          )
        : {};
      setRequestFormProperty({
        property: 'batchParameters',
        value,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProductProfile]);

  useEffect(() => {
    if (highlightMandatoryFields) {
      setTimeout(() => {
        setHighlightMandatoryFields(false);
      }, 5000);
    }
  }, [highlightMandatoryFields]);

  const canDownloadBatch = includes(requestForm.actions, 'download_batch');
  const [isConfirmDisabled, setIsConfirmDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    setIsConfirmDisabled(true); // Disable button on click
    setIsLoading(true); // Show loading spinner
    const serializedRequest = serializeDeviceBatchRequest(requestForm);
    await onSubmit(serializedRequest);
    setIsConfirmDisabled(false); // Enable button after request completes
    setIsLoading(false); // Hide loading spinner
  };

  return (
    <div id="device-batch-request-form" className="DeviceBatchRequestForm">
      <div className="form-header d-flex">
        <div className="mt-5 ml-5 d-flex text-muted">
          <h3 className="text-muted">Device Batch Request</h3>
          <span
            onClick={(): void => {
              if (requestForm.status === 'Awaiting Approval') {
                onChangeMode();
              }
            }}
            className="ml-3 mt-2 cursor-pointer"
          >
            {canEdit && <FontAwesomeIcon icon={readOnly ? faLock : faUnlock} />}
          </span>
        </div>
        <div className="ml-auto m-3">
          <Button
            id="close-form-button"
            outline
            size="sm"
            onClick={(): void => {
              onCancel();
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </div>
      </div>
      <div className="form-content mt-4 px-5 pb-5">
        <Row>
          <Col>
            <Label className="pki-label">Notes</Label>
            <FormGroup>
              <Input
                value={isEmpty(notes) && readOnly ? 'N/A' : notes}
                readOnly={readOnly}
                plaintext={readOnly}
                type="textarea"
                onChange={(
                  event: React.ChangeEvent<HTMLInputElement>
                ): void => {
                  setRequestFormProperty({
                    property: 'notes',
                    value: event.target.value,
                  });
                }}
                name="notes"
                id="notes"
              />
            </FormGroup>
          </Col>
          {readOnly && actionNotes && (
            <Col>
              <Label className="pki-label">Approver/Cancellation Notes</Label>
              <FormGroup>
                <Input
                  value={actionNotes}
                  readOnly={readOnly}
                  plaintext={readOnly}
                  type="textarea"
                  name="action_notes"
                  id="action_notes"
                />
              </FormGroup>
            </Col>
          )}
        </Row>
        {readOnly && batchId && (
          <>
            <Label className="pki-label">Device Batch Id</Label>
            <FormGroup>
              <Input
                value={batchId}
                readOnly={readOnly}
                plaintext={readOnly}
                type="text"
                name="batchId"
                id="batchId"
              />
            </FormGroup>
          </>
        )}
        <Row>
          <Col md={6}>
            <Label className="pki-label">Product Profile</Label>
            {!readOnly && (
              <ServerSideSelect
                onSelectEntity={(profile) => {
                  setRequestFormProperty({
                    property: 'productProfileUuid',
                    value: profile?.uuid,
                  });
                  setCurrentProductProfile(deserializeProductProfile(profile));
                }}
                id="product-profile-uuid"
                formatter={(profile) => profile.name}
                disabled={readOnly}
                fetchUrl={`product-profile`}
                searchParam={`name`}
                error={
                  highlightMandatoryFields && !currentProductProfile
                    ? 'Product Profile is required'
                    : undefined
                }
                value={currentProductProfile?.name || ''}
              />
            )}
            {readOnly && (
              <Input
                readOnly={readOnly}
                plaintext={true}
                value={defaultValues?.productProfileName || ''}
              />
            )}
          </Col>
          <Col md={6}>
            <Label className="pki-label">Quantity</Label>
            <div className="d-flex">
              <Input
                id="quantity"
                readOnly={readOnly}
                type={readOnly ? 'text' : 'number'}
                min={1}
                required
                value={readOnly ? quantity.toLocaleString('en-US') : quantity}
                plaintext={readOnly}
                onChange={(
                  event: React.ChangeEvent<HTMLInputElement>
                ): void => {
                  setRequestFormProperty({
                    property: 'quantity',
                    value: event.target.value,
                  });
                }}
              />
            </div>
            {(!quantity || quantity < 1) && highlightMandatoryFields && (
              <div className="invalid-text">Quantity needs to be &gt;= 1</div>
            )}
          </Col>
        </Row>
        <Collapse isOpen={!!size(batchParameters)}>
          <KeyNInput
            simpleObject={readOnly}
            readOnly={readOnly}
            className="mt-3"
            label="Variables"
            highlightMandatoryFields={highlightMandatoryFields}
            keys={batchParameters || {}}
            onChange={({
              key,
              value,
            }: {
              key: string;
              value: string;
            }): void => {
              setRequestFormProperty({
                property: 'batchParameters',
                value: { ...batchParameters, [key]: value },
              });
            }}
            labelPopover="These variables are defined in either the Metadata and/or Secrets for the selected Product Profile."
          />
        </Collapse>
        {readOnly && canDownloadBatch && requestForm.status === 'Completed' && (
          <div className="float-right mt-5 pb-5">
            <span>
              <Button
                id="download-batch-button"
                outline
                onClick={(): void => {
                  onDownloadDeviceBatch(requestForm);
                }}
              >
                Download Device Batch
              </Button>
            </span>
          </div>
        )}
        {!readOnly && (
          <div className="float-right mt-5 pb-5">
            <span className="mr-2">
              <Button
                id="cancel-form-button"
                outline
                onClick={(): void => {
                  onCancel();
                }}
              >
                Cancel
              </Button>
            </span>
            <span>
              <Button
                id="confirm-form-button"
                outline
                disabled={isConfirmDisabled}
                onClick={handleSubmit}
              >
                Confirm
              </Button>
            </span>
            {isLoading && (
              <Spinner
                size={'sm'}
                className={'ml-2 btn-group-vertical'}
                type={'border'}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default DeviceBatchRequestForm;
