import React, { FC, useState } from 'react';
import { orderBy, repeat } from 'lodash';
import { useSelector } from 'react-redux';
import { Device } from '../../types';
import { ProductMetadataSecret } from '../../../ProductProfiles/types';
import { Table } from '../../../../components';
import { InputReveal } from '../../../../components/InputReveal/InputReveal';
import { ApplicationState } from '../../../../store';
import { UsersState } from '../../../../store/users/types';

interface MetadataSecretsProps {
  type: 'metadata' | 'secrets';
  device?: Device;
}

interface StateProps {
  users: UsersState;
}

export const MetadataSecrets: FC<MetadataSecretsProps> = ({ type, device }) => {
  const {
    users: { userProfile },
  } = useSelector<ApplicationState, StateProps>((pki) => ({
    users: pki.users,
  }));

  const canSeeSecrets = device?.actions?.includes('get_secret');
  const data = orderBy(
    Object.entries(
      (type === 'metadata' ? device?.metadata : device?.secrets) || {}
    ).map(([name, value]) => {
      return {
        name,
        value,
      };
    }),
    ['name'],
    ['asc']
  );

  const hasCryptoRole = userProfile.entityRoles.find(
    ({ role }) => role === 'crypto_user'
  );

  const columns = [
    {
      dataField: 'name',
      text: 'Name',
      sort: false,
      formatter: (name: string, metadataSecret: ProductMetadataSecret) => {
        return name;
      },
    },
    {
      dataField: 'value',
      text: 'Value',
      sort: false,
      formatter: (value: string, metadataSecret: ProductMetadataSecret) => {
        return value ? (
          <InputReveal
            value={value}
            prefix={type}
            secret={type === 'secrets'}
          />
        ) : (
          <></>
        );
      },
    },
  ];
  return (
    <Table
      data={data}
      columns={columns}
      remote={false}
      // If you want to drop the search box (is just client side search) please set the value below to false
      search={true}
      keyField={'name'}
    />
  );
};
