import React, { useEffect, useState, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Collapse, Button, Row, Col, Modal } from 'reactstrap';
import _ from 'lodash';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import moment, { Moment } from 'moment';
import { dateFormatter } from '../../libs/helpers';
import { ApplicationState } from '../../store';
import { getAuditLogs } from '../../store/auditLogs/actions';
import { AuditLogsState, AuditLog } from '../../store/auditLogs/types';
import {
  Table,
  Spinner,
  DatePicker,
  Select,
  ErrorHandler,
} from '../../components';
import AuditLogPreview from './AuditLogPreview';

import './AuditLogs.scss';

const AuditLogs: FC = () => {
  const {
    isGettingAuditLogsList,
    isLoadedAuditLogsList,
    auditLogsList,
    auditLogsErrors,
  } = useSelector<ApplicationState, AuditLogsState>((pki) => pki.auditLogs);

  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const [showContent, setShowContent] = useState<boolean>(false);
  const [currentLog, setCurrentLog] = useState<AuditLog>();
  const [previewModal, setPreviewModal] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Moment>(moment().startOf('day'));
  const [endDate, setEndDate] = useState<Moment>(moment().endOf('day'));
  const [limit, setLimit] = useState<string>('500');

  const dispatch = useDispatch();

  useEffect(() => {
    if (isGettingAuditLogsList) {
      setShowSpinner(true);
    }
    if (isLoadedAuditLogsList || auditLogsErrors) {
      setShowContent(true);
      setShowSpinner(false);
    }
    return function cleanup(): void {
      setShowContent(false);
    };
  }, [auditLogsErrors, isGettingAuditLogsList, isLoadedAuditLogsList]);

  useEffect(() => {
    const tokenSource = axios.CancelToken.source();
    dispatch(
      getAuditLogs({
        startDate: moment().startOf('day'),
        endDate: moment().endOf('day'),
        limit: '500',
      })
    );
    return function cleanup(): void {
      tokenSource.cancel('AuditLogs::getAuditLogs');
    };
  }, [dispatch]);

  const toggleModal = (): void => {
    setPreviewModal((prev) => !prev);
  };

  const columns = [
    { dataField: 'id', text: 'id', hidden: true },
    { dataField: 'eventName', text: 'Event Name' },
    {
      dataField: 'timestamp',
      text: 'Timestamp',
      formatter: (date: number): string => dateFormatter(date),
      csvFormatter: (date: number): string => dateFormatter(date),
    },
    { dataField: 'eventOutcome', text: 'Outcome' },
    {
      dataField: 'authorizedUser',
      text: 'User',
      formatter: (user: string): string =>
        !_.isEmpty(user) ? user : '-- No User Related --',
    },
  ];

  return (
    <div className="AuditLogs">
      <Card className="rounded p-5">
        <div className="header-contanier d-flex">
          <h3 className="text-muted">Audit Logs</h3>
          {showSpinner && (
            <Spinner className="mt-2 ml-2" size="sm" type="border" />
          )}
        </div>
        <div className="date-filter my-3">
          <Row>
            <Col md={4}>
              <DatePicker
                label="Start Date"
                format="LLL"
                showTimePicker={true}
                value={startDate}
                onChange={(value: Moment): void => {
                  setStartDate(value);
                }}
              />
            </Col>
            <Col md={4}>
              <DatePicker
                label="End Date"
                format="LLL"
                className="ml-2"
                showTimePicker={true}
                value={endDate}
                onChange={(value: Moment): void => {
                  setEndDate(value);
                }}
              />
            </Col>
            <Col md={2}>
              <Select
                className="ml-2"
                selectedKey={limit}
                options={[
                  { value: '100', key: '100' },
                  { value: '500', key: '500' },
                  { value: '1000', key: '1000' },
                  { value: '5000', key: '5000' },
                  { value: '10000', key: '10000' },
                ]}
                onChange={({ key }: { key: string }): void => {
                  setLimit(key);
                }}
                label="Limit"
              />
            </Col>
            <Col md={2}>
              <Button
                onClick={(): void => {
                  dispatch(getAuditLogs({ startDate, endDate, limit }));
                }}
                size="sm"
                className="filter-button w-100 float-right ml-2 p-1"
                outline
              >
                <FontAwesomeIcon className="mr-1" icon={faFilter} /> Filter
              </Button>
            </Col>
          </Row>
        </div>
        <Collapse isOpen={showContent}>
          <div className="view mt-5">
            {auditLogsErrors ? (
              <ErrorHandler />
            ) : (
              <Table
                data={auditLogsList}
                keyField="id"
                noDataIndication="No Audit Logs"
                sort={{ dataField: 'timestamp', order: 'desc' }}
                remote={false}
                exportCSV={{
                  fileName: 'audit-logs.csv',
                  onlyExportSelection: true,
                  customExport: true,
                }}
                rowEvents={{
                  onClick: (notUsedValue: null, current: AuditLog): void => {
                    setCurrentLog(current);
                    setPreviewModal(true);
                  },
                }}
                selectRow={{
                  mode: 'checkbox',
                  clickToSelect: false,
                }}
                columns={columns}
              />
            )}
          </div>
        </Collapse>
      </Card>
      <Modal
        className="PKIApp"
        size="lg"
        toggle={toggleModal}
        isOpen={previewModal}
      >
        {currentLog && (
          <AuditLogPreview auditLog={currentLog} onCancel={toggleModal} />
        )}
      </Modal>
    </div>
  );
};

export default AuditLogs;
