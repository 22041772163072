import React, { useState, useEffect, useRef, FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTimes,
  faTrash,
  faLock,
  faUnlock,
  faDownload,
  faUpload,
} from '@fortawesome/free-solid-svg-icons';
import {
  Row,
  Col,
  Label,
  FormGroup,
  Input,
  Button,
  Collapse,
  FormFeedback,
} from 'reactstrap';
import _ from 'lodash';
import crypto from 'crypto';
import axios, { AxiosError, CancelTokenSource } from 'axios';
import { useDispatch } from 'react-redux';
import { CodeSigningRequest } from '../../store/requests/types';
import { CodeSigningProfile } from '../../store/codeSigningProfiles/types';
import { Toggle } from '../../components';
import { api, formatBytes, isValidHex, unhexlify } from '../../libs/helpers';
import { defaultValuesCodeSigningRequest } from './constants';
import { signatureAlgorithmNoPadding } from '../../libs/constants';
import { ServerSideSelect } from '../../components/ServerSideSelect/ServerSideSelect';
import { deserializeCodeSigningProfile } from '../../store/codeSigningProfiles/helpers';
import { sendNotification } from '../../store/notifications/actions';

const { saveAs } = require('file-saver');

interface Props {
  onCancel?: Function;
  onSubmit?: Function;
  onChangeMode?: Function;
  defaultValues?: CodeSigningRequest;
  readOnly: boolean;
}

const CodeSigningRequestForm: FC<Props> = ({
  onCancel = (): null => null,
  onSubmit = (): null => null,
  onChangeMode = (): null => null,
  defaultValues = defaultValuesCodeSigningRequest,
  readOnly = false,
}) => {
  const [
    currentCodeSigningProfile,
    setCurrentCodeSigningProfile,
  ] = useState<CodeSigningProfile | null>(null);
  const [highlightMandatoryFields, setHighlightMandatoryFields] = useState(
    false
  );

  const [requestForm, setRequestForm] = useState<CodeSigningRequest>(
    defaultValues
  );
  const [currentFile, setCurrentFile] = useState<File>();
  const [showFreeForm, setShowFreeForm] = useState(false);
  const [isProfileDeleted, setIsProfileDeleted] = useState(false);

  const inputEl = useRef<HTMLInputElement>(null);
  const fetchCancelTokenSource = useRef<CancelTokenSource>();
  const {
    notes,
    digest,
    codeSigningProfileUuid,
    signature,
    uuid,
    approverNotes,
  } = requestForm;
  const dispatch = useDispatch();

  const setProfileFormProperty = ({
    property,
    value,
  }: {
    property: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any;
  }): void => {
    setRequestForm((current: CodeSigningRequest) => ({
      ...current,
      [property]: value,
    }));
  };

  const isEditable =
    _.includes((defaultValues as CodeSigningRequest).actions, 'update') &&
    (defaultValues as CodeSigningRequest).status === 'Awaiting Approval';

  const signingHashAlgorithm = currentCodeSigningProfile?.signingHashAlgorithm;
  const signingAlgorithm = currentCodeSigningProfile?.signingAlgorithm;

  const isValidHexDigest = digest === '' || isValidHex(digest);
  const isDigestNotNull = digest !== '';

  useEffect(() => {
    if (highlightMandatoryFields) {
      setTimeout(() => {
        setHighlightMandatoryFields(false);
      }, 5000);
    }
  }, [highlightMandatoryFields]);

  const fetchCurrentCodeSigningProfile = async () => {
    fetchCancelTokenSource.current = axios.CancelToken.source();
    try {
      const { data: profile } = await api().get(
        `code-signing/profile/${codeSigningProfileUuid}`,
        {
          cancelToken: fetchCancelTokenSource.current?.token,
        }
      );
      setCurrentCodeSigningProfile(deserializeCodeSigningProfile(profile));
    } catch (e) {
      if ((e as AxiosError).response?.status === 404) {
        setIsProfileDeleted(true);
      } else if (!axios.isCancel(e)) {
        sendNotification({
          text: e?.response?.data?.detail || 'Failed to load the data!',
          success: false,
        })(dispatch);
      }
    }
  };
  useEffect(() => {
    if (uuid && codeSigningProfileUuid && readOnly) {
      fetchCurrentCodeSigningProfile();
    }
    return () => {
      fetchCancelTokenSource.current?.cancel(
        'Code signing request form got unmounted'
      );
    };
  }, []);

  useEffect(() => {
    if (signingAlgorithm === signatureAlgorithmNoPadding) {
      setCurrentFile(undefined);
      setProfileFormProperty({
        property: 'digest',
        value: digest,
      });

      setShowFreeForm(true);
    } else {
      setShowFreeForm(false);
    }
  }, [signingAlgorithm]);

  const isValid =
    isValidHexDigest && isDigestNotNull && currentCodeSigningProfile;

  return (
    <div id="code-signing-request-form" className="CodeSigningRequestForm">
      <div className="form-header d-flex">
        <div className="mt-5 ml-5 d-flex text-muted">
          <h3 className="text-muted">Code Signing Request</h3>
          <span
            onClick={(): void => {
              if (requestForm.status === 'Awaiting Approval') {
                onChangeMode();
              }
            }}
            className="ml-3 mt-2 cursor-pointer"
          >
            {isEditable && (
              <FontAwesomeIcon icon={readOnly ? faLock : faUnlock} />
            )}
          </span>
        </div>
        <div className="ml-auto m-3">
          <Button
            id="close-form-button"
            outline
            size="sm"
            onClick={(): void => {
              onCancel();
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </div>
      </div>
      <div className="form-content mt-4 px-5 pb-5">
        <Row>
          <Col>
            <Label className="pki-label">Notes</Label>
            <FormGroup>
              <Input
                value={_.isEmpty(notes) && readOnly ? 'N/A' : notes}
                readOnly={readOnly}
                plaintext={readOnly}
                type="textarea"
                onChange={(
                  event: React.ChangeEvent<HTMLInputElement>
                ): void => {
                  setProfileFormProperty({
                    property: 'notes',
                    value: event.target.value,
                  });
                }}
                name="notes"
                id="notes"
              />
            </FormGroup>
          </Col>
          {readOnly && approverNotes && (
            <Col>
              <Label className="pki-label">Approver Notes</Label>
              <FormGroup>
                <Input
                  value={approverNotes}
                  readOnly={readOnly}
                  plaintext={readOnly}
                  type="textarea"
                  name="approver_notes"
                  id="approver_notes"
                />
              </FormGroup>
            </Col>
          )}
        </Row>
        <Row>
          <Col md={6}>
            <Label
              className="pki-label"
              for={
                readOnly
                  ? 'code-signing-profile-name'
                  : 'code-signing-profile-uuid'
              }
            >
              Code Signing Profile
            </Label>
            {!readOnly && (
              <>
                <ServerSideSelect
                  onSelectEntity={(profile: CodeSigningProfile) => {
                    setCurrentCodeSigningProfile(
                      deserializeCodeSigningProfile(profile)
                    );
                    setProfileFormProperty({
                      property: 'codeSigningProfileUuid',
                      value: profile?.uuid,
                    });
                  }}
                  placeholder={'Select a code signing profile'}
                  disabled={readOnly}
                  id={'code-signing-profile-uuid'}
                  formatter={(profile) => profile?.name || 'N/A'}
                  fetchUrl={`code-signing/profile`}
                  urlParams={[
                    ['action', 'create_signature'],
                    ['resource', 'code_signing_profile'],
                  ]}
                  searchParam={`name`}
                  searchOperator={'~'}
                  error={
                    !currentCodeSigningProfile && highlightMandatoryFields
                      ? 'Code signing profile is required'
                      : undefined
                  }
                />
              </>
            )}
            {readOnly && (
              <Input
                readOnly
                id={'code-signing-profile-name'}
                title={`${
                  isProfileDeleted ? 'Code Signing Profile is deleted' : ''
                }`}
                className={`${isProfileDeleted ? 'line-through' : ''}`}
                value={
                  defaultValues?.codeSigningProfileName ||
                  currentCodeSigningProfile?.name ||
                  'N/A'
                }
                plaintext
              />
            )}
          </Col>
          <Col md={3}>
            <Label className="pki-label">Signature Hash Algorithm</Label>
            <FormGroup>
              <Input
                id="sign-hash-algorithm"
                readOnly
                value={
                  signingAlgorithm === signatureAlgorithmNoPadding
                    ? 'N/A'
                    : _(signingHashAlgorithm).startCase().toUpperCase()
                }
                plaintext
              />
            </FormGroup>
          </Col>
          <Col md={3}>
            <Label className="pki-label">
              {readOnly ? 'Show Hex Digest' : 'Hex Input'}
            </Label>
            <FormGroup>
              <Toggle
                id="toggle-digest-input"
                onChange={(checked: boolean): void => {
                  setShowFreeForm(checked);
                }}
                disabled={signingAlgorithm === signatureAlgorithmNoPadding}
                checked={showFreeForm}
              />
            </FormGroup>
          </Col>
        </Row>
        {!readOnly && (
          <div className="digest-container mt-2">
            <div className="upload-code-image">
              <Label className="pki-label">Code Image</Label>
              <Row>
                <Col className="d-flex" md={2}>
                  <Button
                    outline
                    disabled={signingAlgorithm === signatureAlgorithmNoPadding}
                    id="upload-digst-button"
                    onClick={(): void => {
                      inputEl?.current?.click();
                    }}
                  >
                    <FontAwesomeIcon className="mr-1" icon={faUpload} />
                    Select
                  </Button>
                </Col>
                {currentFile && (
                  <Col className="d-flex" md={8}>
                    <div
                      id="file-description"
                      className="text-truncate mt-auto"
                    >
                      {currentFile.name} ({formatBytes(currentFile.size)})
                    </div>
                  </Col>
                )}
                {currentFile && (
                  <Col className="d-flex" md={2}>
                    <FontAwesomeIcon
                      className="pki-ico file-delete ml-auto mt-4"
                      onClick={(): void => {
                        setCurrentFile(undefined);
                        setProfileFormProperty({
                          property: 'digest',
                          value: '',
                        });
                        if (
                          inputEl &&
                          inputEl.current &&
                          inputEl.current.value
                        ) {
                          _.set(inputEl, 'current.value', null);
                        }
                      }}
                      icon={faTrash}
                    />
                  </Col>
                )}
                <input
                  type="file"
                  id="file-input"
                  className="d-none"
                  ref={inputEl}
                  onChange={({ target: { files } }): void => {
                    if (files && files.length > 0) {
                      setCurrentFile(files[0]);
                      const reader = new FileReader();

                      reader.onload = (): void => {
                        if (reader.result && signingHashAlgorithm) {
                          const content = reader.result;
                          const hexDigest = crypto
                            .createHash(signingHashAlgorithm)
                            .update(new Uint8Array(content as ArrayBuffer))
                            .digest('hex');

                          setProfileFormProperty({
                            property: 'digest',
                            value: String(hexDigest),
                          });
                        }
                      };

                      reader.readAsArrayBuffer(files[0]);
                      _.set(inputEl, 'current.value', null);
                    }
                  }}
                />
              </Row>
            </div>
          </div>
        )}
        {!showFreeForm &&
          !currentFile &&
          highlightMandatoryFields &&
          signingAlgorithm !== signatureAlgorithmNoPadding && (
            <div className="invalid-text">
              Cannot be empty, please upload a file!
            </div>
          )}
        {showFreeForm &&
          !isValidHexDigest &&
          signingAlgorithm !== signatureAlgorithmNoPadding && (
            <div className="invalid-text">
              Something went wrong calculating the digest.
            </div>
          )}
        <Collapse isOpen={showFreeForm}>
          <div className="mt-3">
            <Label className="pki-label">Hex Digest</Label>
            <FormGroup>
              <Input
                value={digest}
                invalid={
                  !isValidHexDigest ||
                  (!isDigestNotNull && highlightMandatoryFields)
                }
                readOnly={readOnly}
                plaintext={readOnly}
                type="textarea"
                onChange={(
                  event: React.ChangeEvent<HTMLInputElement>
                ): void => {
                  setProfileFormProperty({
                    property: 'digest',
                    value: event.target.value,
                  });
                }}
                name="digest-input"
                id="digest-input"
              />
              {!isValidHexDigest && (
                <FormFeedback>
                  Has to be a valid Hexadecimal string.
                </FormFeedback>
              )}
              {!isDigestNotNull && highlightMandatoryFields && (
                <FormFeedback>
                  Cannot be empty, insert a valid Hexadecimal string!
                </FormFeedback>
              )}
            </FormGroup>
          </div>
        </Collapse>
        {signature && (
          <Row>
            <Col>
              <Label className="pki-label">
                Download Signature{' '}
                <FontAwesomeIcon
                  onClick={(): void => {
                    const u8 = unhexlify(String(signature));
                    const blob = new Blob([u8], {
                      type: 'application/octet-stream',
                    });
                    saveAs(blob, `${codeSigningProfileUuid}-${uuid}.bin`);
                  }}
                  className="pki-ico ml-2"
                  icon={faDownload}
                />
              </Label>
            </Col>
          </Row>
        )}
        {!readOnly && (
          <div className="float-right mt-5 pb-5">
            <span className="mr-2">
              <Button
                id="cancel-form-button"
                outline
                onClick={(): void => {
                  onCancel();
                }}
              >
                Cancel
              </Button>
            </span>
            <span>
              <Button
                id="confirm-form-button"
                outline
                disabled={false}
                onClick={(): void => {
                  onSubmit({ values: requestForm, isValid });
                  if (!isValid) {
                    setHighlightMandatoryFields(true);
                  }
                }}
              >
                Confirm
              </Button>
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default CodeSigningRequestForm;
